export default {
  variant: `links.mute`,
  p: 1,
  m: 0,
  mx: 2,
  svg: {
    display: `block`,
    mx: `auto`,
    mb: 1
  }
}
