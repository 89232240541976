import React from 'react'
import { Box, Input } from 'theme-ui'

const SearchFallback = (
  <Box
    sx={{
      borderStyle: `solid`,
      borderWidth: `md`,
      borderColor: `white`,
      borderRadius: `default`,
      display: [`none`, `none`, `block`]
    }}
  >
    <Input disabled />
  </Box>
)

export default SearchFallback
