import common from './common'

export default {
  ...common.button,
  color: `background`,
  bg: `primary`,
  borderColor: `primary`,
  ':hover': {
    bg: `grayDark`,
    borderColor: `grayDark`
  }
}
