export default {
  color: `primary`,
  textDecoration: `none`,
  userSelect: `none`,
  ':visited': {
    color: 'primary'
  },
  ':hover': {
    color: 'primaryDark'
  }
}
